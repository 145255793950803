import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "./Contact.css"; // Assurez-vous que les classes CSS sont définies ici
import ReCAPTCHA from "react-google-recaptcha";

const onChange = () => {}
const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [disabled, setDisabled] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    display: false,
    message: "",
    type: "",
  });
  const [captchaToken, setCaptchaToken] = useState(null); // État pour le token captcha

  const url = `${process.env.REACT_APP_BACKEND_URL}/contact-us`;

  const toggleAlert = (message, type) => {
    setAlertInfo({ display: true, message, type });

    setTimeout(() => {
      setAlertInfo({ display: false, message: "", type: "" });
    }, 5000);
  };

  const onSubmit = async (data) => {
    const { fullName, email, subject, message } = data;
    try {
      setDisabled(true);

      // Obtenez le token reCAPTCHA
      grecaptcha.enterprise.ready(async () => {
        const token = await grecaptcha.enterprise.execute('6LeX71YqAAAAAP2WCjLepwFAbshEyXM8qBs51AN4', { action: 'submit' });
        setCaptchaToken(token); // Stocker le token dans l'état

        // Envoyer les données du formulaire au backend
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ fullName, email, subject, message, captchaToken: token }),
        });

        const result = await response.json();

        if (response.ok) {
          toggleAlert(result.message, 'success');
        } else {
          toggleAlert(result.message || 'Une erreur est survenue. Veuillez réessayer.', 'danger');
        }
      });
    } catch (e) {
      console.error(e);
      toggleAlert("Une erreur est survenue. Veuillez réessayer.", "danger");
    } finally {
      setDisabled(false);
      reset();
    }
  };

  return (
    <>
      <Navbar />
      <div className="main">
        <div className="content">
          <div className="form">
            <h1 className="tab-content">Contactez-nous</h1>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="field-wrap">
                <label className={`req ${errors.fullName ? "highlight" : ""}`}>
                  Nom complet<span className="req">*</span>
                </label>
                <input
                  type="text"
                  name="fullName"
                  {...register("fullName", { required: "Nom complet requis" })}
                  className={`${errors.fullName ? "error-msg active" : ""}`}
                />
                {errors.fullName && (
                  <div className="error-msg active">{errors.fullName.message}</div>
                )}
              </div>

              <div className="field-wrap">
                <label className={`req ${errors.email ? "highlight" : ""}`}>
                  Email<span className="req">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  {...register("email", {
                    required: "Email requis",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: "Email invalide",
                    },
                  })}
                  className={`${errors.email ? "error-msg active" : ""}`}
                />
                {errors.email && (
                  <div className="error-msg active">{errors.email.message}</div>
                )}
              </div>

              <div className="field-wrap">
                <label className={`req ${errors.subject ? "highlight" : ""}`}>
                  Sujet<span className="req">*</span>
                </label>
                <input
                  type="text"
                  name="subject"
                  {...register("subject", {
                    required: "Sujet requis",
                  })}
                  className={`${errors.subject ? "error-msg active" : ""}`}
                />
                {errors.subject && (
                  <div className="error-msg active">{errors.subject.message}</div>
                )}
              </div>

              <div className="field-wrap">
                <label className={`req ${errors.message ? "highlight" : ""}`}>
                  Message<span className="req">*</span>
                </label>
                <textarea
                  name="message"
                  {...register("message", {
                    required: "Message requis",
                  })}
                  className={`${errors.message ? "error-msg active" : ""}`}
                ></textarea>
                {errors.message && (
                  <div className="error-msg active">{errors.message.message}</div>
                )}
              </div>
              <ReCAPTCHA sitekey="6LeiLFcqAAAAAClioBEZrfd7XNT6GZtEUy5VN0MW" onChange={onChange} />,
              <button
                type="submit"
                className="button button-block"
                disabled={disabled}
              >
                Envoyer
              </button>
            </form>
            {alertInfo.display && (
              <div className={`error-msg active alert-${alertInfo.type}`}>
                {alertInfo.message}
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;

import React from "react";
import { Link } from "react-router-dom";
import "./About.css";

const About = () => {
  return (
    <>
      <div className="about" id="about">
        <div className="col-2">
          <h2 style={{ color: "black" }}>Comment jouer ?</h2>
          <span className="line"></span>
          <div
            className="content"
            style={{
              marginBottom: "3rem",
              fontSize: "1.8rem",
              textAlign: "justify",
            }}
          >
            <div>
              Afin de fêter l’ouverture de notre 10ème boutique à Nice, nous
              organisons un jeu-concours de type tirage au sort.
            </div>
            <div>
              Vous trouverez sur tous vos tickets de caisse et vos factures de
              vos achats chez Thétiptop,{" "}
              <b>
                d'un montant dépassant 49€, un numéro composé de 10 chiffres.
              </b>
            </div>
            <div>
              Vous le récupérez, vous vous inscrivez ici sur notre plateforme,
              et vous tentez votre chance à gagner nos <b>5 lots</b>, et à
              l'issu du jeu concours vous participez au grand tombola et
              tenterez de gagner <b>un an de thé d'un montant de 360€ !</b>
            </div>
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              100% des tickets seront gagnants
            </div>
          </div>

          {/* Ajout du paragraphe avec les règles du jeu concours */}
          <div
            className="rules"
            style={{
              marginBottom: "3rem",
              fontSize: "1.8rem",
              textAlign: "justify",
            }}
          >
            <h3 style={{ fontWeight: "bold", textAlign: "center", color: "#000000", }}>
            🎉 Règles du Jeu Concours 🎉:
            </h3>
            <ol style={{ paddingLeft: "20px", marginTop: "1rem" }}>
              <li style={{ marginBottom: "1rem" }}>
                <b>1- Inscription/Connexion :</b> Créez un compte ou connectez-vous si vous êtes déjà inscrit.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <b>2- Entrée du numéro :</b> Saisissez le numéro de participation reçu pour valider votre entrée.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <b>3- Voir et récupérer le lot :</b> Si vous gagnez, le lot sera visible dans la section "Mes Récompenses" de votre compte.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <b>4- Récupération en magasin :</b> Rendez-vous dans un magasin partenaire pour récupérer votre gain en présentant les détails de votre lot.
              </li>
            </ol>
          </div>

          <Link to="/participer">
            <button className="button">Participer</button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default About;
